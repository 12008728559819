const Product = [
  {
    id: 1,
    payment_id: "price_1LyHCGH0x74QhW3tgmIVimgi",
    name: "Camera",
    desc: "This is Canon Brand Camera",
    descLong: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam velit inventore consectetur repellat odit, sequi excepturi! Eaque eum culpa quibusdam repellat, ipsum rem quam unde quis obcaecati illum provident et!",
    price: 13000000,
    stock: 10,
    img: "images/camera.png",
  },
  {
    id: 2,
    payment_id: "price_1LyHDLH0x74QhW3tam5Je0R3",
    name: "Laptop",
    desc: "This is Dell Brand Laptop",
    descLong: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Neque iusto beatae esse ducimus, optio illum veniam qui ea sequi assumenda necessitatibus est! Delectus dolore quos voluptatem atque numquam iste dicta.",
    price: 18000000,
    stock: 0,
    img: "images/laptop.png",
  },
  {
    id: 3,
    payment_id: "price_1LyHE2H0x74QhW3ti4jBBLD1",
    name: "Smartphone",
    desc: "This is Apple Iphone 14",
    descLong: "Lorem ipsum dolor sit amet consectetur adipisicing elit. At illo odit, ratione dolorem minus necessitatibus ad, placeat ipsa, deserunt id soluta magnam dicta rem! Placeat id nisi doloribus dolorum similique?",
    price: 23000000,
    stock: 1,
    img: "images/phone.png",
  },
];

export default Product;
