import React from "react";
import CartItem from "./CartItem";

const CartScreen = ({ cartData, onRemove }) => {
  const calculateAll = () => {
    let cartTotal = 0;
    for (let i = 0; i < cartData.length; i++) {
      cartTotal += cartData[i].totalPrice;
    }

    return cartTotal;
  };

  const total = calculateAll();

  const checkOut = async () => {
    await fetch("http://localhost:8000/checkout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ items: cartData }),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (response.url) {
          window.location.assign(response.url);
        }
      });
  };

  return (
    <>
      <h1 className="text-center">Cart Page</h1>
      <div className="row my-5">
        <div className="col-xl-8 col-md-6 col-sm-12">{cartData.length > 0 ? cartData.map((cart) => <CartItem key={cart.id} cart={cart} onRemove={onRemove} />) : <h1>No Items</h1>}</div>
        <div className="col-xl-4 col-md-6 col-sm-12">
          {cartData.length > 0 && (
            <div className="card my-3">
              <ul className="list-group list-group-flush">
                <li className="list-group-item">Total Price: Rp.{total}</li>
                <li className="list-group-item">
                  <button className="btn btn-dark w-100" onClick={() => checkOut()}>
                    Checkout
                  </button>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CartScreen;
