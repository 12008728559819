import React, { useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";

const ProductScreen = ({ productData, onAdd, onPurchased }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const index = productData.findIndex((e) => e.id === parseInt(id));
  const data = productData[index];
  console.log(data.img);
  const [counter, setCounter] = useState(0);

  const incrementQty = () => {
    setCounter(counter + 1);
  };

  const decrementQty = () => {
    setCounter(counter - 1);
  };

  return (
    <>
      <Link to="/" className="btn btn-dark mb-3 w-25">
        Back
      </Link>
      <div className="row">
        <div className="col-xl-4 col-md-4 col-sm-12">
          <img src={window.location.origin + "/" + data.img} className="card-img-top img-fluid" alt={data.name} style={{ objectFit: "cover", Maxheight: "800px" }} />
        </div>
        <div className="col-xl-4 col-md-4 col-sm-12">
          <h1>{data.name}</h1>
          <p>{data.descLong}</p>
          <p>Rp.{data.price}</p>
        </div>
        <div className="col-xl-4 col-md-4 col-sm-12">
          <div className="card">
            <ul className="list-group list-group-flush">
              {data.stock === 0 ? (
                <li className="list-group-item">Not in Stock</li>
              ) : (
                <li className="list-group-item">
                  Purchasing Qty:
                  {counter === 0 ? (
                    <button className="btn btn-dark mx-1" disabled>
                      -
                    </button>
                  ) : (
                    <button
                      className="btn btn-dark mx-1"
                      onClick={() => {
                        decrementQty();
                      }}
                    >
                      -
                    </button>
                  )}
                  <div className="btn btn-light">{counter}</div>
                  {counter >= data.stock ? (
                    <button className="btn btn-dark mx-1" disabled>
                      +
                    </button>
                  ) : (
                    <button
                      className="btn btn-dark mx-1"
                      onClick={() => {
                        incrementQty();
                      }}
                    >
                      +
                    </button>
                  )}
                </li>
              )}

              <li className="list-group-item">
                {data.stock === 0 || counter === 0 ? (
                  <button className="btn btn-secondary w-100" disabled>
                    Add To Cart
                  </button>
                ) : (
                  <button
                    className="btn btn-dark w-100"
                    onClick={() => {
                      if (window.confirm("Add To Cart")) {
                        onAdd({
                          id: data.id,
                          payment_id: data.payment_id,
                          name: data.name,
                          img: data.img,
                          qty: counter,
                          totalPrice: counter * data.price,
                        });
                        onPurchased({
                          id: data.id,
                          qty: counter,
                        });
                        navigate("/");
                      }
                    }}
                  >
                    Add To Cart
                  </button>
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductScreen;
