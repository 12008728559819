import React from "react";
import { Link } from "react-router-dom";

const ProductItem = ({ item }) => {
  return (
    <Link to={`/product/${item.id}`} style={{ textDecoration: "none" }}>
      <div className="card my-2">
        <img src={window.location.origin + "/" + item.img} className="card-img-top img-fluid" alt={item.name} style={{ objectFit: "cover", height: "200px" }} />
        <div className="card-body">
          <h5 className="card-title">{item.name}</h5>
          <p className="card-text " style={{ color: "black" }}>
            {item.desc}
          </p>
          <p className="card-text" style={{ color: "black" }}>
            Rp. {item.price}
          </p>
        </div>
      </div>
    </Link>
  );
};

ProductItem.defaultProps = {
  item: {
    id: +new Date(),
    name: "Product Title",
    desc: "Product Description",
    price: 0,
    stock: 10,
    img: "images/camera.png",
  },
};

export default ProductItem;
