import React from "react";
import ProductItem from "./ProductItem";

const ProductList = ({ productData }) => {
  return (
    <div className="row">
      {productData.map((prod) => (
        <div key={prod.id} className="col-xl-4 col-md-6 col-sm-12">
          <ProductItem item={prod} />
        </div>
      ))}
    </div>
  );
};

export default ProductList;
