import React from "react";

const Cancel = () => {
  return (
    <div>
      <h1>Sorry to see you cancel your Stripe Payment</h1>
    </div>
  );
};

export default Cancel;
