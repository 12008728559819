import Navbar from "./components/Navbar";
import Main from "./components/Main";
import CartScreen from "./components/Cart/CartScreen";
import ProductScreen from "./components/Product/ProductScreen";
import Success from "./components/Pages/Success";
import Cancel from "./components/Pages/Cancel";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Product from "./Productdata";
import { useState } from "react";

function App() {
  const productList = {
    products: Product,
    setProduct: function (data) {
      this.products = data;
    },
  };
  const [cartItems, setCartItems] = useState([]);

  const updateProduct = (purchased) => {
    const index = productList.products.findIndex((x) => x.id === purchased.id);
    if (index !== -1) {
      const oldData = productList.products[index];
      oldData.stock -= purchased.qty;

      const otherProducts = productList.products.filter((item) => item.id !== oldData.id);
      productList.setProduct([...otherProducts, oldData]);
    }
  };

  const refundProduct = (canceled) => {
    const index = productList.products.findIndex((x) => x.id === canceled.id);
    if (index !== -1) {
      const oldData = productList.products[index];
      oldData.stock += canceled.qty;

      const otherProducts = productList.products.filter((item) => item.id !== oldData.id);
      productList.setProduct([...otherProducts, oldData]);
    }
  };

  const addToCart = (productInfo) => {
    if (cartItems.length > 0) {
      const index = cartItems.findIndex((x) => x.id === parseInt(productInfo.id));
      if (index !== -1) {
        const oldData = cartItems[index];
        productInfo.qty += oldData.qty;
        productInfo.totalPrice += oldData.totalPrice;
        setCartItems(cartItems.filter((item) => item.id !== oldData.id));
      }
      setCartItems((cartItem) => [...cartItem, productInfo]);
    } else {
      setCartItems((cartItem) => [...cartItem, productInfo]);
    }
  };

  const removeFromCart = (item) => {
    const updatedData = {
      id: item.id,
      qty: item.qty,
    };

    refundProduct(updatedData);

    setCartItems(cartItems.filter((cart) => cart.id !== item.id));
  };

  return (
    <Router>
      <Navbar />
      <div className="container my-5">
        <Routes>
          <Route path="/" element={<Main productData={productList.products} />} />
          <Route path="/cart" element={<CartScreen cartData={cartItems} onRemove={removeFromCart} />} />
          <Route path="/product/:id" element={<ProductScreen productData={productList.products} onPurchased={updateProduct} onAdd={addToCart} />} />
          <Route path="/success" element={<Success />} />
          <Route path="/cancel" element={<Cancel />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
