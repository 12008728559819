import React from "react";
import ProductList from "./Product/ProductList";

const Main = ({ productData }) => {
  return (
    <div className="text-center">
      <h1 className="mb-5">Product Page</h1>
      <ProductList productData={productData} />
    </div>
  );
};

export default Main;
