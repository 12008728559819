import React from "react";

const CartItem = ({ cart, onRemove }) => {
  return (
    <div className="card my-3">
      <div className="row d-flex align-items-center">
        <div className="col-4">
          <img src={window.location.origin + "/" + cart.img} alt={cart.name} className="img-thumbnail img-fluid" />
        </div>
        <div className="col-6">
          <p>
            {cart.qty} x {cart.name} @Rp.{cart.totalPrice}
          </p>
        </div>
        <div className="col-2">
          <button
            className="btn btn-danger"
            onClick={() => {
              if (window.confirm("Remove Item From Cart ?")) {
                onRemove(cart);
              }
            }}
          >
            X
          </button>
        </div>
      </div>
    </div>
  );
};

export default CartItem;
